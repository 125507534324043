<template>
    <div class="promotion-list-main">
        <div class="list-item" v-for="(item,index) in activityList" :key="index"  @click="goDetails(item)">
            <div class="img" :style="'background-image:url('+item.bgUrl+')'"></div>
            <div class="activity-infor">
                <p class="name">{{item.name}}</p>
                <p class="time">{{$t("promotion.deadline")}}：{{item.endDt}}</p>
            </div>
            <span v-if="item.status == 2" class="activityOver">{{$t("promotion.finished")}}</span>
        </div>
    </div>
</template>

<script>
import { promotionQuery } from "@/api/promotion";
export default {
    data(){
        return{
            activityList:[],
        }
    },
    created(){
        document.title = this.$t("promotion.name")
        this.getActivityData()
    },
    methods:{
        async getActivityData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await promotionQuery({});
            toast.clear();
            if(result.data.errorCode == 0){
                this.activityList = result.data.data;
            }
        },
        goDetails(item){
            if(item.status != 2){
                this.$router.push({
                    path:"/promotion/wx",
                    query:{
                        promotionId:item.id
                    }
                })
            }else{
                this.$Toast(this.$t("promotion.EndActivity"));
            }
        }
    }

}
</script>

<style lang="scss">
.promotion-list-main{
    padding:0 0.6rem;
    height: 100%;
    overflow: auto;
    background: #F5F6FA;
    .list-item{
        border-radius: 0.32rem;
        background: #fff;
        overflow: hidden;
        margin-top: 0.4rem;
        position: relative;
        &:last-child{
            margin-bottom: 1rem;
        }
        .activityOver{
            position: absolute;
            top: 0;
            right: 0;
            font-size: 0.52rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0.1rem 0.25rem;
            border-radius: 0px 0.32rem 0px 0.32rem;
            background-color: rgba($color: #000000, $alpha: 0.5);
        }
        .img{
            display: block;
            height: 4.6rem;
            width: 100%;
            background-size: 100% auto;
            background-position: 0 0;
        }
        .activity-infor{
            padding: 0.52rem 0.72rem;
            .name{
                font-size: 0.72rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #303133;
                line-height: 1rem;  
            }
            .time{
                margin-top: 0.1rem;
                font-size: 0.56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #838385;
                line-height: 0.8rem;
            }
        }
    }
}
</style>